export default {
  methods: {
    addParamsToLocation (params) {
      const state = params && typeof params === 'object' && Object.keys(params).length > 0
        ? this.$route.path +
                '?' +
                Object.keys(params)
                  .map(key => {
                    return (
                      encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                    )
                  })
                  .join('&')
        : this.$route.path
      history.pushState(
        {},
        null,
        state
      )
    },
    getParamsFromLocation () {
      return this.$route.query
    },
    setParamToLocation (params) {
      const currentParams = this.$route.query
      if (currentParams && typeof params === 'object') {
        this.addParamsToLocation(Object.assign({}, currentParams, params))
      } else {
        this.addParamsToLocation(Object.assign({}, params))
      }
    },
    unsetParamFromLocation (param) {
      const currentParams = this.$route.query
      delete currentParams[param]
      this.addParamsToLocation(Object.assign({}, currentParams))
    }
  }
}
